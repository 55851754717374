import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import { Container, Row, Col, Nav, Navbar, NavDropdown } from "react-bootstrap"

import "./header.scss"

const Header = props => {
  const data = useStaticQuery(
    graphql`
      query {
        logoSVG: file(relativePath: { eq: "logo.svg" }) {
          publicURL
        }
        atturaLogo: file(relativePath: { eq: "atturra-logo-white.png" }) {
          publicURL
        }

        allMenuMain {
          edges {
            node {
              id
              title
              relative
              below {
                key
                title
                relative
              }
            }
          }
        }
      }
    `
  )

  const [dropdownOpen, setDropdownOpen] = React.useState(0)
  const [dropdownFocus, setDropdownFocus] = React.useState(0)

  let onMouseEnter = index => {
    setDropdownOpen(index)
  }

  let onMouseLeave = () => {
    setDropdownOpen(undefined)
  }

  return (
    <header id="layout-header">
      {/* Ticker Banner */}
      <Container
        fluid={true}
        style={{ backgroundColor: "#034ea2", padding: "10px 15px" }}
        className=" m-auto"
      >
          {/* Ticker banner removed */}
      </Container>
      <Navbar expand="lg">
        <Navbar.Brand>
          <Link to="/">
            <img src={data.logoSVG.publicURL} alt={"logo"} />
          </Link>
        </Navbar.Brand>
        <Nav.Item className="d-lg-none ml-auto">
          <Covid19Link />
        </Nav.Item>
        <Navbar.Toggle aria-controls="layout-header-navbar-nav" />
        <Navbar.Collapse
          id="layout-header-navbar-nav"
          className={"justify-content-end"}
        >
          {data.allMenuMain.edges.map((edge, key) => {
            if (!edge.node.below) {
              return (
                <Nav.Item key={key}>
                  <Link
                    to={edge.node.relative}
                    className="nav-link"
                    activeClassName="active"
                  >
                    {edge.node.title}
                  </Link>
                </Nav.Item>
              )
            } else {
              return (
                <div key={key}>
                  <NavDropdown
                    className={"d-none d-lg-block"}
                    // activeClassName="active"
                    style={{
                      backgroundColor: "transparent",
                      display: "initial",
                      padding: 0,
                    }}
                    onFocus={() => {
                      onMouseEnter(key)
                    }}
                    onMouseOver={() => {
                      onMouseEnter(key)
                    }}
                    onMouseLeave={onMouseLeave}
                    onClick={() => {
                      if (!dropdownFocus) navigate(edge.node.relative)
                    }}
                    show={dropdownOpen === key}
                    title={edge.node.title}
                  >
                    {edge.node.below.map((edge, key) => (
                      <Link
                        key={key}
                        to={edge.relative}
                        onMouseEnter={() => setDropdownFocus(true)}
                        onMouseLeave={() => setDropdownFocus(false)}
                        className="nav-link dropdown-item"
                      >
                        {edge.title}
                      </Link>
                    ))}
                  </NavDropdown>
                  <div className="d-lg-none">
                    <Nav.Item>
                      <Link
                        to={edge.node.relative}
                        className="nav-link nav-link"
                        activeClassName="active"
                      >
                        {edge.node.title}
                      </Link>
                    </Nav.Item>
                    <div style={{ padding: "0 15px" }}>
                      {edge.node.below.map((edge, key) => (
                        <Nav.Item key={key}>
                          <Link
                            to={edge.relative}
                            className="nav-link nav-link"
                            activeClassName="active"
                          >
                            {edge.title}
                          </Link>
                        </Nav.Item>
                      ))}
                    </div>
                  </div>
                </div>
              )
            }
          })}
          <Nav.Item className="d-none d-lg-block">
            <Covid19Link />
          </Nav.Item>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}
export default Header

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

// const FTSG = () => {
//   return (
//     <Container id="an-ftsg-company" fluid={true}>
//       <Row>
//         <Col>
//           <p>An FTS Group Company</p>
//         </Col>
//       </Row>
//     </Container>
//   )
// }

const Covid19Link = () => {
  return (
    <Link
      to={"/covid-19"}
      className="nav-link covid-19"
      activeClassName="active"
    >
      Covid-19
    </Link>
  )
}
