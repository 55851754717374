import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import { Col, Container, Row } from "react-bootstrap"
import "./footer.scss"

const Footer = props => {
  // console.log(props)

  const data = useStaticQuery(
    graphql`
      query {
        allMenuFooter {
          edges {
            node {
              title
              alias
            }
          }
        }
        logo: file(relativePath: { eq: "logo.svg" }) {
          publicURL
        }
        atturaLogo: file(relativePath: { eq: "atturra-logo-white.png" }) {
          publicURL
        }
        linkedin: file(relativePath: { eq: "se_linkedin_torea-bay-blue.svg" }) {
          publicURL
        }
        facebook: file(relativePath: { eq: "se_facebook_torea-bay-blue.svg" }) {
          publicURL
        }
        twitter: file(relativePath: { eq: "se_twitter_torea-bay-blue.svg" }) {
          publicURL
        }
      }
    `
  )

  // const node = data.allBlockContentGlobalFooter.edges[0].node

  // const eckBody = {
  // 	depths: node.field_global_footer_body,
  // 	bricks: node.relationships.field_global_footer_body
  // }

  // console.log(eckBody)

  return (
    <footer className={"c-footer"}>
      {/* <Bricks data={eckBody} /> */}

      <section>
        <Container>
          <Row>
            <Col>
              <img
                src={data.logo.publicURL}
                alt={"Twitter"}
                style={{ height: "50px", margin: "10px" }}
              />
            </Col>
          </Row>
        </Container>
        <Container style={{ paddingBottom: 0 }}>
          <Row className={"justify-content-center"}>
            <Col xs={12} md={10} xl={8} style={{ paddingBottom: 0 }}>
              <Row>
                {data.allMenuFooter &&
                  data.allMenuFooter.edges.map((edge, index) => {
                    return (
                      <Col xs={6} xl={3} key={index}>
                        <Link
                          to={`/${edge.node.alias}`}
                          style={{ fontWeight: "bold" }}
                        >
                          {edge.node.title}
                        </Link>
                      </Col>
                    )
                  })}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <Container className={"container--top-border"}>
          <Row className={"justify-content-center"}>
            <Col xs={12} md={6} xl={4} className={"text-center"}>
              <h5>Get in touch</h5>
              <p>
                <a
                  href="mailto:info@safetyevolved.com.au"
                  style={{ fontWeight: "bold" }}
                >
                  info@safetyevolved.com.au
                </a>
              </p>
              {/* Leave the phone number out unless advised otherwise! */}
              {/* <p>
                <a href="tel:+61738100006" style={{ fontWeight: "bold" }}>
                  +61 (07) 3810 0006
                </a>
              </p> */}
            </Col>
          </Row>
        </Container>
        <Container style={{ paddingTop: 0, paddingBottom: "15px" }}>
          <Row className={"justify-content-center"}>
            <a
              href="https://www.linkedin.com/company/safety-evolved/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={data.linkedin.publicURL}
                alt={"Linked-in"}
                style={{ height: "50px", margin: "10px" }}
              />
            </a>
            <a
              href="https://www.facebook.com/safetyevolved"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src={data.facebook.publicURL}
                alt={"Facebook"}
                style={{ height: "50px", margin: "10px" }}
              />
            </a>
          </Row>
        </Container>
      </section>

      <section
        className={"section dark"}
        style={{
          position: "relative",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        <div
          style={{
            backgroundColor: "rgb(30, 23, 84)",
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
          }}
        ></div>
        <Container fluid={true}>
          <Row className={"justify-content-between"}>
            <Col
              xs={8}
              className={"d-flex text-left"}
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <p className={"mt-auto mb-auto"} style={{ fontSize: "1rem" }}>
                <span className={"col"} style={{ paddingleft: 0 }}>
                  <a
                    href="/sitemap.xml"
                    style={{ color: "#ffffff", textDecoration: "underline" }}
                  >
                    Sitemap
                  </a>
                </span>
                <span className={"col"}>
                  <a
                    href="/privacy-policy"
                    style={{ color: "#ffffff", textDecoration: "underline" }}
                  >
                    Privacy policy
                  </a>
                </span>
                <span className={"col"}>
                  <a
                    href="/terms-use"
                    style={{ color: "#ffffff", textDecoration: "underline" }}
                  >
                    Terms of use
                  </a>
                </span>
                <span className={"col"} style={{ paddingRight: 0 }}>
                  © {props.siteTitle} {new Date().getFullYear()}
                </span>
              </p>
            </Col>
            <Col
              xs={4}
              className={"d-flex justify-content-end"}
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <a
                href="https://atturra.com/au-en/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt={"Atturra Logo"}
                  src={data.atturaLogo.publicURL}
                  style={{ height: "25px" }}
                />
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
